@import "modules/responsive-type.scss";

$primary: #300743;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {  
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {  
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.navbar-default {
    background: linear-gradient(
        right,
        rgba($primary,0.75),
        rgba($wht,1),
        rgba($wht,1),
        rgba($wht,1),
        rgba($primary,0.75)
        );

    font-weight: bold;
    text-shadow: 0px 0px 5px $wht;


    @media (max-width: 767px) {
        background: white;
    }

    .logo {
        filter: drop-shadow(0px 0px 10px $wht);
    }
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}
.modal-title {
    color: $wht;
}
.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 75%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $wht;
    font-size: 15px;
    a {
        color: $wht;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        color: $wht;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
        font-size: 1rem;
        color: #777;

    h1,h2,h3,h4 {
        color: $primary;
    }


    h2 {
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }

    h3 {
        @media (max-width: 767px) {
            font-size: 17px;
            letter-spacing: 1px;
            line-height: 30px;
        }
    }  
    
    & ol {
        li {
            font-size: 0.9rem;
            color: #777;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}






/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
            /* 4. #Intro */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/


#intro {
    padding: 0;
    margin-top: 0px !important;
    background: linear-gradient(
        rgba($primary,0.75),
        rgba($primary,0.75)
        ),
    url(../img/img3.jpg) no-repeat center;
    background-size: cover;
    height: 100vh;
}

#intro .introItem h1{
    padding-bottom:30px;
}

#intro .introItem{
    padding-bottom:10px;
}

#intro .introItem h1, #intro .introItem h1 small{
    color:white;
}


#intro .introItem i, #intro .next{
    color:white; /* #intro text icon color */
}

.maskWrapper{
    opacity:0.7;
}

.simple-image-mask{
    /*background: #4c4c4c; *//* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    /*background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjNGM0YzRjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjNTk1OTU5IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjNjY2NjY2IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzklIiBzdG9wLWNvbG9yPSIjNDc0NzQ3IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMmMyYzJjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTElIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjAlIiBzdG9wLWNvbG9yPSIjMTExMTExIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNzYlIiBzdG9wLWNvbG9yPSIjMmIyYjJiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iOTElIiBzdG9wLWNvbG9yPSIjMWMxYzFjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzEzMTMxMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);*/
    /*background: -moz-linear-gradient(-45deg,  #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%); *//* FF3.6+ */
    /*background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#4c4c4c), color-stop(12%,#595959), color-stop(25%,#666666), color-stop(39%,#474747), color-stop(50%,#2c2c2c), color-stop(51%,#000000), color-stop(60%,#111111), color-stop(76%,#2b2b2b), color-stop(91%,#1c1c1c), color-stop(100%,#131313)); *//* Chrome,Safari4+ */
    /*background: -webkit-linear-gradient(-45deg,  #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); *//* Chrome10+,Safari5.1+ */
    /*background: -o-linear-gradient(-45deg,  #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); *//* Opera 11.10+ */
    /*background: -ms-linear-gradient(-45deg,  #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); *//* IE10+ */
    /*background: linear-gradient(135deg,  #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); *//* W3C */
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=1 ); *//* IE6-8 fallback on horizontal gradient */

    background: #260833; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMjYwODMzIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTIlIiBzdG9wLWNvbG9yPSIjMjgwNjM4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjUlIiBzdG9wLWNvbG9yPSIjNTcyZTZiIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzklIiBzdG9wLWNvbG9yPSIjM2MxOTRjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMzcwYTRjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNTElIiBzdG9wLWNvbG9yPSIjMjcwNjM1IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjAlIiBzdG9wLWNvbG9yPSIjMjUwODMzIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNzYlIiBzdG9wLWNvbG9yPSIjNDMwZDViIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iOTElIiBzdG9wLWNvbG9yPSIjMWMxYzFjIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzEzMTMxMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(-45deg,  #260833 0%, #280638 12%, #572e6b 25%, #3c194c 39%, #370a4c 50%, #270635 51%, #250833 60%, #430d5b 76%, #1c1c1c 91%, #131313 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,#260833), color-stop(12%,#280638), color-stop(25%,#572e6b), color-stop(39%,#3c194c), color-stop(50%,#370a4c), color-stop(51%,#270635), color-stop(60%,#250833), color-stop(76%,#430d5b), color-stop(91%,#1c1c1c), color-stop(100%,#131313)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg,  #260833 0%,#280638 12%,#572e6b 25%,#3c194c 39%,#370a4c 50%,#270635 51%,#250833 60%,#430d5b 76%,#1c1c1c 91%,#131313 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg,  #260833 0%,#280638 12%,#572e6b 25%,#3c194c 39%,#370a4c 50%,#270635 51%,#250833 60%,#430d5b 76%,#1c1c1c 91%,#131313 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg,  #260833 0%,#280638 12%,#572e6b 25%,#3c194c 39%,#370a4c 50%,#270635 51%,#250833 60%,#430d5b 76%,#1c1c1c 91%,#131313 100%); /* IE10+ */
    background: linear-gradient(135deg,  #260833 0%,#280638 12%,#572e6b 25%,#3c194c 39%,#370a4c 50%,#270635 51%,#250833 60%,#430d5b 76%,#1c1c1c 91%,#131313 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#260833', endColorstr='#131313',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */


    left: 0;
    top:0;
    overflow:hidden;
    z-index:-9999;
    height:100%;
    width:100%;
    position:absolute;
}







/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/* 1.General page/section customizations  */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

html,body{
    height: 100%;
    max-width:100%;
}


body{
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #d5d5d5;
    font-weight: 400;

}

.container-fluid{
    margin:0;
    padding:0;
}


section{
    padding-top:60px;
    padding-bottom:60px;

    @media (max-width: 767px) {
        padding-top:30px;
        padding-bottom:30px;
    }    


    @media (max-width: 500px) {
        padding-top: 10px;
        padding-bottom: 10px;
    }        
}

.sectionTitle{
    // margin-bottom:30px;
}



.heading h2{
    margin-bottom:25px;
}


.valign{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


::-moz-selection {
    color: white;
    background: #4a0867;
}

::selection {
    color: white;
    background: #4a0867;
}





/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
/* 2. Main text styles and shortcodes */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

p{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color:#777;
}

h1, h2, h3, h4, h5{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

h1 small, h2 small, h3 small, h4 small, h5 small{
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
}

h1{
    font-size: 64px;
    line-height:50px;
    letter-spacing: -3px;
    color:#300743;
 }

h1 small{
    font-size: 64px;
}

h2{
    text-transform: none;
    font-size: 50px;
    line-height:50px;
    letter-spacing: -3px;
    color:#300743;
}

h2 small{
    color:#300743;
    font-size: 50px;
}


h3{
    text-transform: none;
    font-size: 36px;
    line-height:40px;
    letter-spacing: -2px;

}

h3 small{
    font-weight: 200;
    font-size: 36px;
}

h4{
    font-size:30px;
    line-height:30px;
    letter-spacing: -1px;
    color:#300743;

}


h4 small{
    color:#300743;
    font-size: 24px;
}

h5{
    font-size:24px;
    line-height:26px;
}

.intro-text{
    letter-spacing: 2px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 23px;
    color:$primary;
    // margin-bottom:40px;

}

/*  small intro text  */
.sub{
    border:1px solid #300743;
    padding:6px 20px;
    font-weight:bold;
    letter-spacing:2px;
    font-style:normal;
    font-size: 13px;
    text-transform:uppercase;
    color:#300743;

        @media (max-width: 1200px) {
         border: none;
         border-bottom: 1px solid $primary;
         margin-bottom: 1em;
         display: inline-block;
        }    

}

/* p {
    padding:6px 20px;
    font-weight:bold;
    letter-spacing:2px;
    font-style:normal;
    font-size: 13px;
    text-transform:uppercase;
    color:#300743;

        @media (max-width: 1200px) {
         border: none;
         border-bottom: 1px solid $primary;
         margin-bottom: 1em;
         display: inline-block;
        }    

}
 */

.subWhite{
    font-weight:300;
    border:1px solid white;
    padding:6px 20px;
    letter-spacing:4px;
    text-transform:uppercase;
    color:white;
    font-style:normal;

        @media (max-width: 1200px) {
            border: none;
            display: inline-block;
        }        
}

.sectionTitleIcon{
    border:2px solid#300743;
    border-radius:100px;
    padding:30px;
    color:#300743;
    margin-top:30px;
}

.sectionTitleIcon-white{
    border:2px solid white;
    border-radius:100px;
    padding:30px;
    color:white;
    margin-top:30px;
}


.dropcap {
    background: #300743;
    color: #FFFFFF;
    display: inline-block;
    float: left;
    font-size: 24px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 44px;
    margin-top:7px;
    margin-right:7px;
}

/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        /* ABOUT */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
#about {
    background-color: white;
    position:relative;
    padding-bottom: 0px;
}


/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
        /* SEPARATORS */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/

.separator{
    background-color:#300743;
}

.separator h3, .separator h3 small{
    color:white;
}

.separator .btn{
    margin:20px;
}

.separator p{
    color:white;
}



/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
            /* 7. #Team */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/



#team{
    background: white;
    padding-bottom:0;
}

#team .member img{
    max-width: 100%;
    height: auto;
    filter: drop-shadow(0px 0px 5px $primary);

    @media (max-width: 767px) {
        max-width: 60%;
    }

    @media (max-width: 500px) {
        max-width: 40%;
    }    
}

.memberName{
    color:#300743;
    font-size:20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform:uppercase;
}


.memberJob {
    margin-top:20px;
    margin-bottom:20px;

}

.memberDescription{
    font-size:12px;
    line-height:22px;
    padding:0 40px;
    height:130px;
}


.member p i{
    margin:5px;
}

#team .owl-carousel {
    margin-bottom: -10px;
}

#team  .owl-theme .owl-controls .owl-buttons div {
    position: absolute;
    color: #300743;
    background-color: transparent;
}

#team  .owl-theme .owl-controls .owl-buttons .owl-prev{
    left: 0;
    top: 300px;
}

#team  .owl-theme .owl-controls .owl-buttons .owl-next{
    right: 0;
    top: 300px;
}




#formSection {
    background: $wht;
}



.owl-theme .owl-controls .owl-page {
    display: none;
}

.primaryTxt {
    color: $primary;
}

.primaryBg {
    background: $primary;
    color: $wht;
    padding: 1em 0em 2em;
    h3 {
        letter-spacing: 1px;
        line-height: 45px;
        
        @media (max-width: 1200px) {
            font-size: 2em;
            line-height: 35px;
        }

        @media (max-width: 990px) {
            font-size: 1.5em;
            line-height: 35px;
        }
    }
}
